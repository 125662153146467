import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

export default function Template({ data }) {
	const { markdownRemark } = data
	const { frontmatter, html, fields, excerpt } = markdownRemark
	return (
		<Layout title={frontmatter.title}>
			<div className="article">
				<Helmet>
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:site" content="@aviaryan123" />
					<meta name="twitter:title" content={frontmatter.title} />
					<meta name="twitter:description" content={excerpt} />
					<meta name="twitter:creator" content="@aviaryan123" />
					<meta name="twitter:image:src" content={'https://aviaryan.com/postImages/' + fields.postUID + '.jpg'} />

					<meta property="og:title" content={frontmatter.title} />
					<meta property="og:type" content="website" />
					<meta property="og:url" content={'https://aviaryan.com/' + fields.slug} />
					<meta property="og:image" content={'https://aviaryan.com/postImages/' + fields.postUID + '.jpg'} />
					<meta property="og:description" content={excerpt} />
					<meta property="og:site_name" content="Avi Aryan" />

					<meta property="article:published_time" content={fields.date} />
					<meta property="article:author" content="https://www.facebook.com/aviaryan123" />
					<meta property="article:publisher" content="https://www.facebook.com/aviaryan123" />
					<meta property="article:section" content="blog" />
					<meta property="article:tag" content={frontmatter.tags} />

					<meta name="description" content={excerpt} />
				</Helmet>
				<h1>{frontmatter.title}</h1>
				<h2 className="post-date">{fields.date}</h2>
				<div className="post-content" dangerouslySetInnerHTML={{ __html: html }} />
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			excerpt(pruneLength: 280)
			frontmatter {
				title
				tags
			}
			fields {
				date(formatString: "MMMM DD, YYYY")
				slug
				postUID
			}
		}
	}
`
